<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <!-- <p>CFE</p> -->
      </div>

      <div class="userheader">
        <img src="@/assets/images/user.png" alt="" class="avatar">
        <h3 class="name">
          <span id="nicknameDisplay" @click="showInput">{{ nickname }}</span>
          <input type="text" id="nicknameInput" class="nicknameEdit" v-model="nickname" @blur="saveNickname" @keyup.enter="saveNickname" style="display:none;" />
          <img src="@/assets/images/bj.png" alt="">
        </h3>
        <p class="identity">{{ walletAddress ? '' + walletAddress : '加载地址中...' }}</p>
      </div>

      <div class="main">
        <div class="card4" @click="invite">
          <div class="Options">
            <img src="@/assets/images/us1.png" alt="">邀请好友
          </div>
          <img src="@/assets/images/arrow.png" alt="">
        </div>

        <div class="card4" @click="myteam">
          <div class="Options">
            <img src="@/assets/images/us2.png" alt="">我的社区
          </div>
          <img src="@/assets/images/arrow.png" alt="">
        </div>
        <div class="card4" @click="income">
          <div class="Options">
            <img src="@/assets/images/us3.png" alt="">我的资产
          </div>
          <img src="@/assets/images/arrow.png" alt="">
        </div>

        <div class="card4" @click="checkAndNavigateToSettings">
          <div class="Options" id="settingsOption">
            <img src="@/assets/images/us4.png" alt="">设置
          </div>
          <img src="@/assets/images/arrow.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ethers } from 'ethers';
import { contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'user',
  data() {
    return {
      walletAddress: '', // 初始化钱包地址为空
      isOwner: false, // 标记是否为合约部署者
      nickname: 'User', // 初始化昵称
    };
  },
  methods: {
    set() {
      this.$router.push('/settings');
    },
    income() {
      this.$router.push('/income');
    },
    myteam() {
      this.$router.push('/myteam');
    },
    invite() {
      this.$router.push('/copy-invite');
    },
    async connectWallet() {
      if (typeof window.ethereum !== 'undefined') {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          this.walletAddress = accounts[0];

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(contractAddress, contractABI, signer);

          const owner = await contract.owner(); // 获取合约部署者地址
          this.isOwner = this.walletAddress.toLowerCase() === owner.toLowerCase(); // 检查当前用户是否为合约部署者
        } catch (error) {
          console.error('连接钱包失败', error);
        }
      } else {
        console.error('请安装 MetaMask!');
      }
    },
    checkAndNavigateToSettings() {
      if (this.isOwner) {
        this.set();
      } else {
        alert('Only the contract deployer can access this setting。');
      }
    },
    showInput() {
      document.getElementById('nicknameInput').style.display = 'inline';
      document.getElementById('nicknameDisplay').style.display = 'none';
      this.$nextTick(() => {
        document.getElementById('nicknameInput').focus();
        document.getElementById('nicknameInput').setSelectionRange(this.nickname.length, this.nickname.length); // 设置光标到文本末尾
      });
    },
    saveNickname() {
      document.getElementById('nicknameInput').style.display = 'none';
      document.getElementById('nicknameDisplay').style.display = 'inline';
    },
  },
  mounted() {
    this.connectWallet();
  },
};
</script>

<style scoped>
@import "@/assets/css/user.css";
@import "@/assets/css/style.css";
</style>