<template>
  <div class="maxbox">
    <div>
      <div class="top">
        <div class="topid">
          <div id="walletAddress">{{ shortWalletAddress }}</div>
          <div id="walletBalanceTop">金额: {{ walletBalanceTop }} USDT</div>
        </div>
        <!-- <p>CFE</p> -->
      </div>
      <div class="main">
        <div class="header">
          <p>Welcome</p>
          <!-- <a href="javascript:void(null);" @click="copyToClipboard">
            <span>分享好友</span>
            <img src="@/assets/images/fx.png" alt="">
          </a> -->
        </div>
        <div class="affivhe">
          <img src="@/assets/images/gg.png" alt="">
          <div id="vipStatus" class="top-left-corner">{{ memberStatus }}</div>
        </div>
        <div class="banner">
          <div class="item1">
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>1.66500000</h2>
                    <p>私募互助</p>
                    <b><span>CFECLUB</span></b>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="card1">
                    <img src="@/assets/images/ban-1.png" alt="">
                    <h2>0.82600000</h2>
                    <p>我的CFE</p>
                    <b><span>互助共赢</span></b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="community">
          <h1>加入社区</h1>
          <p>升级V2需要&nbsp;50USDT<br>升级V3需要&nbsp;60USDT</p>
          <button id="upgradeToV2Button" @click="upgradeMembership('V2', '50')">升级到V2</button>
          <button id="upgradeToV3Button" @click="upgradeMembership('V3', '60')">升级到V3</button>
          <div id="walletBalanceCommunity" style="display: inline-block; margin-left: 20px;"></div>
          <img src="@/assets/images/sq.png" alt="">
        </div>
        <div class="set">
          <input type="text" id="referrer-address" placeholder="输入推荐人地址">
          <input type="number" id="amount" placeholder="输入金额（40或90）">
          <button @click="registerMember">绑定推荐人</button>
        </div>
        <!-- 隐藏的文本框用于备用的复制方法 -->
        <textarea id="clipboardContent" style="position: absolute; top: -9999px; left: -9999px;"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
/* global ethereum */
import Swiper from 'swiper';
import { ethers } from 'ethers';
import { usdtTokenAddress, usdtAbi, contractAddress, contractABI } from '@/js/contract.js';

export default {
  name: 'home',
  data() {
    return {
      walletAddress: '未连接',
      shortWalletAddress: '未连接',
      walletBalanceTop: '0',
      memberStatus: '未获取',
      referrerAddressFromURL: ''
    };
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    });

    this.connectWallet();
    this.getAndDisplayMemberStatus();
    this.extractReferrerFromURL();
  },
  methods: {
    async connectWallet() {
      if (window.ethereum) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();

          // Fetch USDT balance using ERC20 contract
          const usdtContract = new ethers.Contract(usdtTokenAddress, usdtAbi, provider);
          const balance = await usdtContract.balanceOf(address);
          const balanceInUsdt = ethers.utils.formatUnits(balance, 6); // USDT has 6 decimal places
          const balanceInUsdtInt = Math.floor(balanceInUsdt); // 将余额取整

          this.walletAddress = address;
          this.shortWalletAddress = `地址:${address.slice(0, 5)}...${address.slice(-4)}`;
          this.walletBalanceTop = balanceInUsdtInt; // 显示整数

        } catch (error) {
          console.error(error);
          alert('连接钱包失败');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async getAndDisplayMemberStatus() {
      if (!window.ethereum) {
        alert('请安装 MetaMask 插件');
        return;
      }

      try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
        const status = await contract.getMemberStatus(userAddress);

        this.memberStatus = status;
      } catch (error) {
        console.error('获取会员状态失败:', error);
        alert('获取会员状态失败，请查看控制台获取详细信息。');
      }
    },
    copyToClipboard() {
      const textToCopy = `网址: ${window.location.href}\n推荐码: ${this.walletAddress}`;
      if (navigator.clipboard && window.isSecureContext) {
        // 使用 Clipboard API
        navigator.clipboard.writeText(textToCopy).then(() => {
          alert('复制成功');
        }, () => {
          this.fallbackCopyTextToClipboard(textToCopy);
        });
      } else {
        // 使用备用方法
        this.fallbackCopyTextToClipboard(textToCopy);
      }
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.getElementById('clipboardContent');
      textArea.value = text;
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('复制成功');
      } catch (err) {
        alert('复制失败');
      }
    },
    async registerMember() {
      const referrerAddress = this.referrerAddressFromURL || document.getElementById('referrer-address').value;
      const amount = document.getElementById('amount').value;

      if (!referrerAddress || !amount) {
        alert('请输入推荐人地址和金额');
        return;
      }

      if (amount !== '40' && amount !== '90') {
        alert('金额必须是40或90');
        return;
      }

      if (window.ethereum) {
        try {
          await ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const userAddress = await signer.getAddress();
          const paymentAmount = ethers.utils.parseUnits(amount.toString(), 6);
          const usdtToken = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

          let allowance = await usdtToken.allowance(userAddress, contractAddress);
          if (!allowance.gte(paymentAmount)) {
            const approveTx = await usdtToken.approve(contractAddress, paymentAmount);
            await approveTx.wait();
          }

          const contract = new ethers.Contract(contractAddress, contractABI, signer);
          const tx = await contract.register(referrerAddress);
          await tx.wait();

          alert('注册成功');
        } catch (error) {
          console.error('注册过程中出错:', error);
          alert('注册失败。详情请查看控制台。');
        }
      } else {
        alert('请安装 MetaMask 插件');
      }
    },
    async upgradeMembership(level, amount) {
      if (!window.ethereum) {
        alert('未检测到MetaMask，请安装后使用此应用。');
        return;
      }

      try {
        await ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        const paymentAmount = ethers.utils.parseUnits(amount, 6);
        const usdtToken = new ethers.Contract(usdtTokenAddress, usdtAbi, signer);

        let allowance = await usdtToken.allowance(userAddress, contractAddress);
        if (allowance.lt(paymentAmount)) {
          const approveTx = await usdtToken.approve(contractAddress, paymentAmount);
          await approveTx.wait();
        }

        const contract = new ethers.Contract(contractAddress, contractABI, signer);
        const tx = level === 'V2' ? await contract.upgradeToV2() : await contract.upgradeToV3();
        await tx.wait();
        alert(`升级到${level}成功！`);
      } catch (error) {
        console.error(`升级到${level}过程中出错:`, error);
        alert(`升级到${level}失败。详情请查看控制台。`);
      }
    },
    extractReferrerFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const referrer = urlParams.get('referrer');
      if (referrer) {
        this.referrerAddressFromURL = referrer;
        document.getElementById('referrer-address').value = referrer;
      }
    }
  },
};
</script>
<style scoped>
@import "@/assets/css/swiper-bundle.min.css";
@import "@/assets/css/index.css";
@import "@/assets/css/style.css";
</style>